import { P1 } from '../OldGeneralComponents';
import { PageNames } from '../../utils/generalUtilities';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Menu from '../Shared/Menu';
const mic = require('../../assets/images/landing/mic.svg');
const slideshow = require('../../assets/images/landing/slideshow.svg');

const ProductsTab = () => {
  const router = useRouter();
  return (
    <div className="group relative ">
      <div className="mr-[7px] rounded-md cursor-pointer px-[18px] py-[10px] text-grey-500 hover:bg-blue-50 hover:text-blue-600">
        <P1>Products</P1>
      </div>
      <Menu
        isHoverable
        customContainerStyle={{
          top: 40,
          width: 274,
          left: 0
        }}
        menuOptions={[
          {
            title: 'AI Video Maker',
            isVisible: true,
            image: (
              <Image
                className="mr-3"
                alt="slideshow"
                loading={'lazy'}
                src={slideshow}
              />
            ),
            onClick: () =>
              window.location.pathname === '/'
                ? window.location.reload()
                : router.push(PageNames.ROOT)
          },
          {
            title: 'Audiogram Maker',
            isVisible: true,
            image: (
              <Image className="mr-3" alt="mic" loading={'lazy'} src={mic} />
            ),
            onClick: () => router.push(PageNames.AUDIOGRAM)
          }
        ]}
      />
    </div>
  );
};

export default ProductsTab;
