

const cacheS3Url = (url: string, initCache = false, isPremium = false) => {
  return url;
  if (!isPremium) return url;
  // Define a regular expression pattern to match URLs containing "jupitrr-ugc" and "amazonaws.com"
  const pattern = /https:\/\/jupitrr-ugc\.s3.*?\.amazonaws\.com\//;

  // Replace the matched portion of the URL with "https://d1ynou9d34h7uz.cloudfront.net/"
  let replacedUrl = url.replace(pattern, 'https://d1ynou9d34h7uz.cloudfront.net/');

  const stagingPattern = /https?:\/\/jupitrr-staging-playground\.s3.*?\.amazonaws\.com\//;

  // Replace the matched portion of the URL with "https://d1ynou9d34h7uz.cloudfront.net/"
  replacedUrl = replacedUrl.replace(stagingPattern, 'https://d1z13af4t0y6gd.cloudfront.net/');

  try {
    if (initCache) fetch(replacedUrl)
  } catch (err) { }
  return replacedUrl;

};

const isJupitrrS3File = (url: string) => url.includes('jupitrr-ugc') ||
  url.includes('jupitrr-staging-playground') ||
  url.includes('d1ynou9d34h7uz.cloudfront.net/') ||
  url.includes('d1z13af4t0y6gd.cloudfront.net/');


const uncacheS3Url = (url: string) => {
  // Define a regular expression pattern to match CloudFront URLs with "https://d1ynou9d34h7uz.cloudfront.net/"
  const pattern = /https:\/\/d1ynou9d34h7uz\.cloudfront\.net\//;

  // Replace the matched portion of the URL with "https://jupitrr-ugc.s3.amazonaws.com/"
  let replacedUrl = url.replace(pattern, 'https://jupitrr-ugc.s3.amazonaws.com/');

  // Define a regular expression pattern to match CloudFront URLs with "https://d1z13af4t0y6gd.cloudfront.net/"
  const stagingPattern = /https:\/\/d1z13af4t0y6gd\.cloudfront\.net\//;

  // Replace the matched portion of the URL with "https://jupitrr-staging-playground.s3.amazonaws.com/"
  replacedUrl = replacedUrl.replace(stagingPattern, 'https://jupitrr-staging-playground.s3.amazonaws.com/');

  return replacedUrl;
};


export { cacheS3Url, uncacheS3Url, isJupitrrS3File }